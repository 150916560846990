import styled from "styled-components";

export const InfobarContainer = styled.div`
  .contentBox {
    width: 100%;
    // min-width: 1280px;
    height: 20px;
    // min-height: 30px;
    background-color: var(--color-background-secondary);
    padding: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    /* position: fixed;
    top: 98px; */
    z-index: 998;
    /* @media (max-width: 414px) {
      top: 86px;
    }
    @media (max-width: 375px) {
      top: 68px;
    } */
    @media (min-width: 1025px) {
      position: fixed;
      top: 98px;
    }
    @media (max-width: 1024px) {
      padding: 12px 20px;
    }
    p {
      font-size: var(--font-size-xs);
      font-weight: var(--font-weight-medium);
      line-height: 22.5px;
      color: var(--color-label-secondary);
      @media (max-width: 768px) {
        font-size: var(--mo-font-size-xs);
      }
    }
    @media (max-width: 345px) {
      padding: 12px 25px;
    }
  }
`;
