// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics, logEvent } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAufszCV8g7dyhQllDH3Obn-f4-psa6p1U",
  authDomain: "fruit-map-6cd12.firebaseapp.com",
  projectId: "fruit-map-6cd12",
  storageBucket: "fruit-map-6cd12.firebasestorage.app",
  messagingSenderId: "639656591211",
  appId: "1:639656591211:web:fe74d242c20d1d37d1162e",
  measurementId: "G-L3R2QHYT1C",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

// 분석 이벤트 기록을 위한 함수 내보내기
export const logAnalyticsEvent = (eventName, eventParams) => {
  logEvent(analytics, eventName, eventParams);
};

export default analytics;
