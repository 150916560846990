import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { HeaderStyles } from "./styles";
import { useLocation } from "react-router-dom";
import {
  useLoginInfoContext,
  useAddressInfoContext,
  useOrdersInfoContext,
  useLoginContext,
  useAddressContext,
} from "../../context/MyContext";
import useWindowSize from "../../hooks/useWindowSize/useWindowSize";
import { logAnalyticsEvent } from "../../firebase";

const Header: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { width } = useWindowSize();
  const [loginInfo, setLoginInfo] = useState<boolean>(false);
  const { setIsUserOrdersInfo } = useOrdersInfoContext(); // 유저의 주문하기 페이지 정보 Context API
  const { setIsUserAddressInfo } = useAddressInfoContext(); // 유저의 주소 정보 Context API
  const { isLoggedIn, setIsLoggedIn } = useLoginInfoContext(); // 로그인 여부 Context
  const { setIsLoginTrue } = useLoginContext(); // 로그인 모달 context
  const { setIsAddressTrue } = useAddressContext(); // 주소 변경 모달 context

  const handleLoginBtnClick = () => {
    setIsLoginTrue(true);
  };

  const handleAddressBtnClick = () => {
    setIsAddressTrue(true);
  };

  // 로그아웃 버튼 함수
  const handleLogoutBtnClick = () => {
    const localLoginInfo = window.localStorage.getItem("loginInfo");
    const sessionLoginInfo = window.sessionStorage.getItem("loginInfo");

    if (localLoginInfo) {
      window.localStorage.clear(); // 로컬 스토리지 모든 항목 삭제
    } else if (sessionLoginInfo) {
      window.sessionStorage.clear(); // 세션 스토리지 모든 항목 삭제
    }
    setLoginInfo(false); //로그인 정보 초기화
    setIsLoggedIn(false); // 로그인 여부 Context API 초기화
    setIsUserOrdersInfo([]); // 주문하기 페이지 정보 Context API 초기화
    setIsUserAddressInfo([]); // 유저 주소 정보 Context API 초기화
    navigate("/"); // 메인 페이지로 이동
  };

  useEffect(() => {
    if (isLoggedIn) {
      setLoginInfo(true);
    } else {
      setLoginInfo(false);
    }
  }, [isLoggedIn]);

  return (
    <HeaderStyles>
      <div className="leftContent">
        <div
          className="logo"
          onClick={() => {
            navigate("/");
          }}
        >
          <div>
            <img
              src={`${process.env.PUBLIC_URL}/images/logo/logo.jpg`}
              alt="logo"
            />
          </div>
        </div>
        {/* {width <= 1024 ? (
            <div
              className="moMenuIcon"
              onClick={() => {
                setIsMobileNavBarTrue(!isMobileNavBarTrue);
              }}
            >
              <div className="icon">
                <FontAwesomeIcon icon={faAngleDown} />
              </div>
            </div>
          ) : null} */}
        {width > 1024 ? (
          <ul>
            <li
              className={location.pathname === "/all-products" ? "active" : ""}
              onClick={() => {
                // navigate("/all-products");
                navigate(
                  `/all-products?page=1&refresh=${new Date().getTime()}`
                );
              }}
            >
              전체상품
            </li>
            {loginInfo && (
              <li
                className={
                  location.pathname === "/price-comparison" ? "active" : ""
                }
                onClick={() => {
                  // 사용자 클릭 이벤트 추적
                  logAnalyticsEvent("button_click", {
                    button_category: "navigation",
                    button_label: "가격표 선택",
                  });

                  navigate(`/price-comparison`);
                }}
              >
                가격표
              </li>
            )}
            {loginInfo && (
              <li
                className={location.pathname === "/notice" ? "active" : ""}
                onClick={() => {
                  navigate("/notice");
                }}
              >
                공지사항
              </li>
            )}
          </ul>
        ) : null}
      </div>
      {width > 1024 && (
        <div className="rightContent">
          <div className="subMemu">
            {loginInfo ? (
              <ul>
                <button
                  className="kakaoButton"
                  onClick={() =>
                    window.open("https://open.kakao.com/o/gGcQb5Hg", "_blank")
                  }
                  type="button"
                >
                  <span className="kakaoIcon">
                    <img
                      src={`${process.env.PUBLIC_URL}/images/icons/open-chat.png`}
                      alt="카카오톡 채팅 아이콘"
                    />
                  </span>
                  오픈채팅
                </button>
                <li
                  onClick={() => {
                    handleLogoutBtnClick();
                  }}
                >
                  로그아웃
                </li>
                <li
                  onClick={() => {
                    navigate("/guide");
                  }}
                >
                  과일여지도 사용법
                </li>
                <li
                  onClick={() => {
                    handleAddressBtnClick();
                  }}
                >
                  정보 변경
                </li>
                <li
                  onClick={() => {
                    // navigate("/customer-service");
                    navigate(
                      `/customer-service?refresh=${new Date().getTime()}`
                    );
                  }}
                >
                  고객센터
                </li>
              </ul>
            ) : (
              <ul>
                <button
                  className="kakaoButton"
                  onClick={() =>
                    window.open("https://open.kakao.com/o/gGcQb5Hg", "_blank")
                  }
                  type="button"
                >
                  <span className="kakaoIcon">
                    <img
                      src={`${process.env.PUBLIC_URL}/images/icons/open-chat.png`}
                      alt="카카오톡 채팅 아이콘"
                    />
                  </span>
                  오픈채팅
                </button>
                <li
                  onClick={() => {
                    handleLoginBtnClick();
                  }}
                >
                  로그인
                </li>
                <li
                  onClick={() => {
                    navigate("/guide");
                  }}
                >
                  과일여지도 사용법
                </li>
                <li
                  onClick={() => {
                    navigate("/customer-service");
                  }}
                >
                  고객센터
                </li>
              </ul>
            )}
          </div>
          {/* <SearchBar
            id="headerSearchBar"
            value={searchValue}
            onChange={handleSearchChange}
            onKeyDown={hadleSearchKeyDown}
          /> */}
          {/* <div className="searchBar">
            <div className="searchImg">
              <FontAwesomeIcon icon={faSearch} />
            </div>
            <input type="text" placeholder="검색" />
          </div> */}
        </div>
      )}
    </HeaderStyles>
  );
};

export default Header;
