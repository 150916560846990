import React from "react";
import { FooterStyles } from "./styles";

const Footer: React.FC = () => {
  return (
    <FooterStyles>
      <div className="footerContentWrap">
        <div className="logoImage">
          {/* <h1>과일여지도</h1> */}
          <h1>
            <img
              src={`${process.env.PUBLIC_URL}/images/logo/logo.jpg`}
              alt="logo"
            />
          </h1>

          <p className="copyright">
            COPYRIGHT©2020 BANGVANG ALL RIGHTS RESERVED.
          </p>
        </div>
        <div className="textBox">
          <p>상호 : 과일여지도</p>
          <p>주소 : 서울특별시 영등포구 당산동1가 174</p>
          <p>프레쉬센터 : 경기도 고양시 덕양구 내유동 506-1</p>
          <p>연락처 : 070-4833-4207</p>
          <p>고객센터 운영시간: AM 10:00 ~ PM 6:00</p>
        </div>
      </div>
    </FooterStyles>
  );
};

export default Footer;
