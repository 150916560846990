// MyContext.tsx
import React, { createContext, useState, useContext, ReactNode } from "react";

// Context에서 제공할 상태와 상태 변경 함수를 정의

// 유저 주소 정보 타입 정의
interface UserAddressInfo {
  // user: string;
  name: string;
  number: string;
}
// 유저 주문하기 페이지 정보 타입 정의
interface UserOrdersInfo {
  productId: number;
  id: number | string;
  itemName: string;
  option: string;
  optionName: string;
  optionValue: string;
  quantity: number;
  price: number;
  priceId: number;
  recipientName: string;
  recipientAddress: string;
  recipientPhone: string;
  shippingMessage: string;
  orderName: string;
  orderAddress: string;
  orderPhone: string;
}

// ProductList 타입 정의
interface ProductList {
  productId: number;
  id: string;
  name: string;
  optionNm: string;
  price: number;
  cnt: number;
}

// 이미지 정보 타입 정의
interface ImageInfo {
  name: string;
  url: string;
}

interface ModalChangeContextProps {
  isModalChangeTrue: boolean; // 모달에서 API를 호출하여 변경점이 있는지 여부
  setIsModalChangeTrue: React.Dispatch<React.SetStateAction<boolean>>; // API호출 상태를 변경하는 함수
  // isModalPropsArr은 제품리스트 모달창에서 주문하기 페이지로 데이터 전달할 때 사용.
  isModalPropsArr?: ProductList[]; // 선택적으로 ProductList 타입의 배열 데이터를 전달할 수 있는 속성
  setIsModalPropsArr?: React.Dispatch<
    React.SetStateAction<ProductList[] | undefined>
  >; //  모달에서 사용할 배열 데이터를 변경하는 함수
}

interface UserOrdersInfoContextProps {
  isUserOrdersInfo: UserOrdersInfo[]; // 유저 주문하기 페이지 정보 (장바구니)
  setIsUserOrdersInfo: React.Dispatch<
    React.SetStateAction<UserOrdersInfo[] | []>
  >;
}

interface UserAdderessInfoContextProps {
  isUserAddressInfo: UserAddressInfo[]; // 유저 주소 정보
  setIsUserAddressInfo: React.Dispatch<
    React.SetStateAction<UserAddressInfo[] | []>
  >;
}

interface LoginInfoContextProps {
  isLoggedIn: boolean; // 로그인 상태 여부
  setIsLoggedIn: React.Dispatch<React.SetStateAction<boolean>>;
}

interface LoginContextProps {
  isLoginTrue: boolean; // 로그인 모달이 열려 있는지 여부
  setIsLoginTrue: React.Dispatch<React.SetStateAction<boolean>>; // 로그인 모달 상태를 변경하는 함수
}

interface CheckSystemContextProps {
  isCheckSystemTrue: boolean; // 시스템 체크 모달이 열려 있는지 여부
  setIsCheckSystemTrue: React.Dispatch<React.SetStateAction<boolean>>; // 시스템 체크 모달 상태를 변경하는 함수
  maintenanceText: {
    jobTime: string;
    jobMessage: string;
    email: string;
  };
  setMaintenanceText: React.Dispatch<
    React.SetStateAction<{
      jobTime: string;
      jobMessage: string;
      email: string;
    }>
  >;
}

interface AddressContextProps {
  isAddressTrue: boolean; // 주소 입력 모달이 열려 있는지 여부
  setIsAddressTrue: React.Dispatch<React.SetStateAction<boolean>>; // 주소 입력 모달 상태를 변경하는 함수
}

interface OrderHistoryContextProps {
  isOrderHistoryTrue: boolean; // 주문 내역 모달이 열려 있는지 여부
  setIsOrderHistoryTrue: React.Dispatch<React.SetStateAction<boolean>>; // 주문 내역 모달 상태를 변경하는 함수
}

interface ProductListContextProps {
  isProductListTrue: boolean; // 상품 리스트 모달이 열려 있는지 여부
  setIsProductListTrue: React.Dispatch<React.SetStateAction<boolean>>; // 상품 리스트 모달 상태를 변경하는 함수
}

interface BulkOrderModalContextProps {
  isBulkOrderModalTrue: boolean; // 대량 주문 모달이 열려 있는지 여부
  setIsBulkOrderModalTrue: React.Dispatch<React.SetStateAction<boolean>>; // 대량 주문 모달 상태를 변경하는 함수
}

interface ImageModalContextProps {
  isImageModalTrue: boolean; // 이미지 모달이 열려 있는지 여부
  setIsImageModalTrue: React.Dispatch<React.SetStateAction<boolean>>; // 이미지 모달 상태를 변경하는 함수
  imageInfo: ImageInfo[];
  setImageInfo: React.Dispatch<React.SetStateAction<ImageInfo[] | []>>;
}

interface SenderInfoModalContextProps {
  isSenderInfoModalTrue: boolean; // 보낸이 정보 모달이 열려 있는지 여부
  setIsSenderInfoModalTrue: React.Dispatch<React.SetStateAction<boolean>>; // 보낸이 정보 모달 상태를 변경하는 함수
}

interface MobileNavBarContextProps {
  isMobileNavBarTrue: boolean; // 모바일 메뉴바 열려 있는지 여부
  setIsMobileNavBarTrue: React.Dispatch<React.SetStateAction<boolean>>; // 보낸이 정보 모달 상태를 변경하는 함수
}

// Context Provider에 children 타입을 지정

interface ModalChangeContextProviderProps {
  children: ReactNode;
}

interface LoginInfoContextProviderProps {
  children: ReactNode;
}

interface UserOrdersInfoContextProviderProps {
  children: ReactNode;
}

interface UserAddressInfoContextProviderProps {
  children: ReactNode;
}

interface CheckSystemContextProviderProps {
  children: ReactNode;
}

interface LoginContextProviderProps {
  children: ReactNode;
}

interface AddressContextProviderProps {
  children: ReactNode;
}
interface OrderHistoryContextProviderProps {
  children: ReactNode;
}
interface ProductListContextProviderProps {
  children: ReactNode;
}

interface BulkOrderModalContextProviderProps {
  children: ReactNode;
}

interface ImageModalContextProviderProps {
  children: ReactNode;
}

interface SenderInfoModalContextProviderProps {
  children: ReactNode;
}

interface MobileNavBarContextProviderProps {
  children: ReactNode;
}

// 각각의 Context를 생성. 초기값은 undefined로 설정
const ModalChangeContext = createContext<ModalChangeContextProps | undefined>(
  undefined
);
const LoginInfoContext = createContext<LoginInfoContextProps | undefined>(
  undefined
);

const UserOrdersInfoContext = createContext<
  UserOrdersInfoContextProps | undefined
>(undefined);

const UserAddressInfoContext = createContext<
  UserAdderessInfoContextProps | undefined
>(undefined);

const LoginContext = createContext<LoginContextProps | undefined>(undefined);

const CheckSystemContext = createContext<CheckSystemContextProps | undefined>(
  undefined
);
const AddressContext = createContext<AddressContextProps | undefined>(
  undefined
);
const OrderHistoryContext = createContext<OrderHistoryContextProps | undefined>(
  undefined
);
const ProductListContext = createContext<ProductListContextProps | undefined>(
  undefined
);
const BulkOrderModalContext = createContext<
  BulkOrderModalContextProps | undefined
>(undefined);
const ImageModalContext = createContext<ImageModalContextProps | undefined>(
  undefined
);
const SenderInfoModalContext = createContext<
  SenderInfoModalContextProps | undefined
>(undefined);
const MobileNavBarContext = createContext<MobileNavBarContextProps | undefined>(
  undefined
);

// 모달 API 호출 상태 관리를 위한 Context Provider를 정의
export const ModalChangeContextProvider: React.FC<
  ModalChangeContextProviderProps
> = ({ children }) => {
  const [isModalChangeTrue, setIsModalChangeTrue] = useState(false); // 모달 API 호출 상태를 useState로 관리
  // 상태 초기값으로 빈 배열 사용
  const [isModalPropsArr, setIsModalPropsArr] = useState<
    ProductList[] | undefined
  >(undefined); // 선택적으로 존재할 수 있도록 설정
  // context 트루의 최상단에 위치 해야함
  return (
    <ModalChangeContext.Provider
      value={{
        isModalChangeTrue,
        setIsModalChangeTrue,
        isModalPropsArr,
        setIsModalPropsArr,
      }}
    >
      {children} {/* 자식 컴포넌트를 렌더링 */}
    </ModalChangeContext.Provider>
  );
};

// 로그인 여부 상태 관리를 위한 Context Provider를 정의
export const LoginInfoContextProvider: React.FC<
  LoginInfoContextProviderProps
> = ({ children }) => {
  const [isLoggedIn, setIsLoggedIn] = useState(false); // 로그인 여부 상태를 useState로 관리

  return (
    <LoginInfoContext.Provider value={{ isLoggedIn, setIsLoggedIn }}>
      {children} {/* 자식 컴포넌트를 렌더링 */}
    </LoginInfoContext.Provider>
  );
};

// 유저 주문하기 페이지 정보 상태 관리를 위한 Context Provider를 정의
export const UserOrdersInfoContextProvider: React.FC<
  UserAddressInfoContextProviderProps
> = ({ children }) => {
  const [isUserOrdersInfo, setIsUserOrdersInfo] = useState<
    UserOrdersInfo[] | []
  >([]);

  return (
    <UserOrdersInfoContext.Provider
      value={{ isUserOrdersInfo, setIsUserOrdersInfo }}
    >
      {children} {/* 자식 컴포넌트를 렌더링 */}
    </UserOrdersInfoContext.Provider>
  );
};

// 유저의 주소 정보 상태 관리를 위한 Context Provider를 정의
export const UserAddressInfoContextProvider: React.FC<
  UserAddressInfoContextProviderProps
> = ({ children }) => {
  const [isUserAddressInfo, setIsUserAddressInfo] = useState<
    UserAddressInfo[] | []
  >([]);

  return (
    <UserAddressInfoContext.Provider
      value={{ isUserAddressInfo, setIsUserAddressInfo }}
    >
      {children} {/* 자식 컴포넌트를 렌더링 */}
    </UserAddressInfoContext.Provider>
  );
};

// 로그인 모달 상태 관리를 위한 Context Provider를 정의
export const LoginContextProvider: React.FC<LoginContextProviderProps> = ({
  children,
}) => {
  const [isLoginTrue, setIsLoginTrue] = useState(false); // 로그인 모달 상태를 useState로 관리

  return (
    <LoginContext.Provider value={{ isLoginTrue, setIsLoginTrue }}>
      {children} {/* 자식 컴포넌트를 렌더링 */}
    </LoginContext.Provider>
  );
};

// 시스템 체크 모달 상태 관리를 위한 Context Provider를 정의
export const CheckSystemContextProvider: React.FC<
  CheckSystemContextProviderProps
> = ({ children }) => {
  const [isCheckSystemTrue, setIsCheckSystemTrue] = useState(false);
  const [maintenanceText, setMaintenanceText] = useState({
    jobTime: "00:00 ~ 00:00",
    jobMessage: "시스템 점검을 진행하고 있습니다.",
    email: "support@example.com",
  });

  return (
    <CheckSystemContext.Provider
      value={{
        isCheckSystemTrue,
        setIsCheckSystemTrue,
        maintenanceText,
        setMaintenanceText,
      }}
    >
      {children}
    </CheckSystemContext.Provider>
  );
};

// 주소 입력 모달 상태 관리를 위한 Context Provider를 정의
export const AddressContextProvider: React.FC<AddressContextProviderProps> = ({
  children,
}) => {
  const [isAddressTrue, setIsAddressTrue] = useState(false); // 주소 입력 모달 상태를 useState로 관리

  return (
    <AddressContext.Provider value={{ isAddressTrue, setIsAddressTrue }}>
      {children} {/* 자식 컴포넌트를 렌더링 */}
    </AddressContext.Provider>
  );
};

// 주문 내역 모달 상태 관리를 위한 Context Provider를 정의
export const OrderHistoryContextProvider: React.FC<
  OrderHistoryContextProviderProps
> = ({ children }) => {
  const [isOrderHistoryTrue, setIsOrderHistoryTrue] = useState(false); // 주문 내역 모달 상태를 useState로 관리

  return (
    <OrderHistoryContext.Provider
      value={{ isOrderHistoryTrue, setIsOrderHistoryTrue }}
    >
      {children} {/* 자식 컴포넌트를 렌더링 */}
    </OrderHistoryContext.Provider>
  );
};

// 상품 리스트 모달 상태 관리를 위한 Context Provider를 정의
export const ProductListContextProvider: React.FC<
  ProductListContextProviderProps
> = ({ children }) => {
  const [isProductListTrue, setIsProductListTrue] = useState(false); // 상품 리스트 모달 상태를 useState로 관리

  return (
    <ProductListContext.Provider
      value={{ isProductListTrue, setIsProductListTrue }}
    >
      {children} {/* 자식 컴포넌트를 렌더링 */}
    </ProductListContext.Provider>
  );
};

// 대량 주문 모달 상태 관리를 위한 Context Provider를 정의
export const BulkOrderModalContextProvider: React.FC<
  BulkOrderModalContextProviderProps
> = ({ children }) => {
  const [isBulkOrderModalTrue, setIsBulkOrderModalTrue] = useState(false); // 대량 주문 모달 상태를 useState로 관리

  return (
    <BulkOrderModalContext.Provider
      value={{ isBulkOrderModalTrue, setIsBulkOrderModalTrue }}
    >
      {children} {/* 자식 컴포넌트를 렌더링 */}
    </BulkOrderModalContext.Provider>
  );
};

// 이미지 모달 상태 관리를 위한 Context Provider를 정의
export const ImageModalContextProvider: React.FC<
  ImageModalContextProviderProps
> = ({ children }) => {
  const [isImageModalTrue, setIsImageModalTrue] = useState(false); // 이미지 모달 상태를 useState로 관리
  const [imageInfo, setImageInfo] = useState<ImageInfo[] | []>([]);

  return (
    <ImageModalContext.Provider
      value={{ isImageModalTrue, setIsImageModalTrue, imageInfo, setImageInfo }}
    >
      {children} {/* 자식 컴포넌트를 렌더링 */}
    </ImageModalContext.Provider>
  );
};

// 보낸이 정보 모달 상태 관리를 위한 context Provider 정의
export const SenderInfoModalContextProvider: React.FC<
  SenderInfoModalContextProviderProps
> = ({ children }) => {
  const [isSenderInfoModalTrue, setIsSenderInfoModalTrue] = useState(false); // 이미지 모달 상태를 useState로 관리

  return (
    <SenderInfoModalContext.Provider
      value={{ isSenderInfoModalTrue, setIsSenderInfoModalTrue }}
    >
      {children} {/* 자식 컴포넌트를 렌더링 */}
    </SenderInfoModalContext.Provider>
  );
};

// 모바일 메뉴바 상태 관리를 위한 context Provider 정의
export const MobileNavBarContextProvider: React.FC<
  MobileNavBarContextProviderProps
> = ({ children }) => {
  const [isMobileNavBarTrue, setIsMobileNavBarTrue] = useState(true); // 이미지 모달 상태를 useState로 관리

  return (
    <MobileNavBarContext.Provider
      value={{ isMobileNavBarTrue, setIsMobileNavBarTrue }}
    >
      {children} {/* 자식 컴포넌트를 렌더링 */}
    </MobileNavBarContext.Provider>
  );
};

// 모달의 API호출 상태 Context를 사용하기 위한 커스텀 훅을 정의
export const useModalChangeContext = () => {
  const context = useContext(ModalChangeContext);
  if (!context) {
    throw new Error(
      "useModalChangeContext must be used within a ModalChangeProvider"
    ); // Context가 없을 때 오류 발생
  }
  return context;
};

// 주문하기 페이지의 정보 Context를 사용하기 위한 커스텀 훅을 정의
export const useOrdersInfoContext = () => {
  const context = useContext(UserOrdersInfoContext);
  if (!context) {
    throw new Error(
      "useOrdersInfoContext must be used within a OrdersInfoProvider"
    ); // Context가 없을 때 오류 발생
  }
  return context;
};

// 주소 정보 Context를 사용하기 위한 커스텀 훅을 정의
export const useAddressInfoContext = () => {
  const context = useContext(UserAddressInfoContext);
  if (!context) {
    throw new Error(
      "useAddressInfoContext must be used within a AddressInfoProvider"
    ); // Context가 없을 때 오류 발생
  }
  return context;
};

// 로그인 정보 Context를 사용하기 위한 커스텀 훅을 정의
export const useLoginInfoContext = () => {
  const context = useContext(LoginInfoContext);
  if (!context) {
    throw new Error("useLoginInfoContext must be used within a LoginProvider"); // Context가 없을 때 오류 발생
  }
  return context;
};

// 로그인 모달 Context를 사용하기 위한 커스텀 훅을 정의
export const useLoginContext = () => {
  const context = useContext(LoginContext);
  if (!context) {
    throw new Error("useLoginContext must be used within a LoginProvider"); // Context가 없을 때 오류 발생
  }
  return context;
};

// 시스템 모달 Context를 사용하기 위한 커스텀 훅을 정의
export const useCheckSystemContext = () => {
  const context = useContext(CheckSystemContext);
  if (!context) {
    throw new Error(
      "useCheckSystemContext must be used within a CheckSystemProvider"
    ); // Context가 없을 때 오류 발생
  }
  return context;
};

// 주소 입력 모달 Context를 사용하기 위한 커스텀 훅을 정의
export const useAddressContext = () => {
  const context = useContext(AddressContext);
  if (!context) {
    throw new Error("useAddressContext must be used within a AddressProvider"); // Context가 없을 때 오류 발생
  }
  return context;
};

// 주문 내역 모달 Context를 사용하기 위한 커스텀 훅을 정의
export const useOrderHistoryContext = () => {
  const context = useContext(OrderHistoryContext);
  if (!context) {
    throw new Error(
      "useOrderHistoryContext must be used within a OrderHistoryProvider"
    ); // Context가 없을 때 오류 발생
  }
  return context;
};

// 상품 리스트 모달 Context를 사용하기 위한 커스텀 훅을 정의
export const useProductListContext = () => {
  const context = useContext(ProductListContext);
  if (!context) {
    throw new Error(
      "useProductListContext must be used within a ProductListProvider"
    ); // Context가 없을 때 오류 발생
  }
  return context;
};

// 대량 주문 모달 Context를 사용하기 위한 커스텀 훅을 정의
export const useBulkOrderModalContext = () => {
  const context = useContext(BulkOrderModalContext);
  if (!context) {
    throw new Error(
      "useBulkOrderModalContext must be used within a BulkOrderModalProvider"
    ); // Context가 없을 때 오류 발생
  }
  return context;
};

// 이미지 모달 Context를 사용하기 위한 커스텀 훅을 정의
export const useImageModalContext = () => {
  const context = useContext(ImageModalContext);
  if (!context) {
    throw new Error(
      "useImageModalContext must be used within a ImgageModalProvider"
    ); // Context가 없을 때 오류 발생
  }
  return context;
};

// 보낸이 정보 모달 Context를 사용하기 위한 커스텀 훅을 정의
export const useSenderInfoModalContext = () => {
  const context = useContext(SenderInfoModalContext);
  if (!context) {
    throw new Error(
      "useSenderInfoModalContext must be used within a SenderInfoModalProvider"
    ); // Context가 없을 때 오류 발생
  }
  return context;
};

// 모바일 메뉴바 Context를 사용하기 위한 커스텀 훅을 정의
export const useMobileNavBarContext = () => {
  const context = useContext(MobileNavBarContext);
  if (!context) {
    throw new Error(
      "useMobileNavBarContext must be used within a MobileNavBarProvider"
    ); // Context가 없을 때 오류 발생
  }
  return context;
};
